import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Table,
  Form,
  DatePicker,
  Empty,
  Select,
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Chart } from "react-google-charts";
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
import dayjs from 'dayjs';

const RegistrationsByUSstates = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [stateregisterationdata, setStateregisterationData] = useState([]);
  const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
  const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
  const [filteredstates, setFilteredstates] = useState([]);
  const [registrationcount, setRegistrationcount] = useState();
  const [period, setPeriod] = useState(0);
  const dateFormat = 'MM/DD/YYYY';

  const newCrumb = [<Link key={1} to="/registration-in-us-states" className="!text-black font-semibold">Registrations by US States</Link>]

  useEffect(() => {
    getHorizontalBarchartdata();
  }, [startdate, enddate])

  const options = {
    title: `${constants.top10UsStates} ${startdate} to ${enddate}`,
    titleTextStyle: {
      fontSize: 20,
      bold: 'true',
      color: '#8a1253',
    },
    vAxis: {
      title: 'States',
      textStyle: {
        fontSize: 14,
        color: '#000',
        bold: 'true'
      },
      titleTextStyle: {
        fontSize: 16,
        color: '#8a1253',
        bold: 'true',
      },
    },
    bar: { groupWidth: "30%" },
    bars: "horizontal",
    is3D: true,
    animation: {
      duration: 1000,
      easing: 'linear',
      startup: true
    },
    legend: { position: 'top' },
    'hAxis': {
      title: 'Registrations', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
        fontSize: 16,
        color: '#8a1253',
        bold: 'true',
      },

    },
    colors: ['#beb000'],
    //chartArea: { left: 140, top: 70, width: "90%", height: "80%" },
    tooltip: { isHtml: true },
  };

  const submitDates = (values) => {
  }

  const getInfoAndRedirect = (record,is_total=null) => {
    if (startdate && enddate) {
      let stateRegistrationData = {};
      stateRegistrationData.startdate = startdate;
      stateRegistrationData.enddate = enddate;
      if(is_total===null){
        stateRegistrationData.country = record.country_id;
        stateRegistrationData.state = record.region_id;
        stateRegistrationData.stateCode = record.region_code;
        stateRegistrationData.countryCode = record.country_code;
      }
      stateRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;
      navigate('/registered-students', { state: { stateRegData: stateRegistrationData } });
    }
  }

  const stateTabColumns = [
    {
      title: 'States',
      key: 'States',
      align: 'left',
      render: (record) => {
        return (
          <div className='linkColor text-sm font-semibold cursor-pointer'
            onClick={() => getInfoAndRedirect(record)}
          > {record.region_name} ({record.region_code})
          </div>
        )
      }
    },
    {
      title: 'Registrations',
      key: 'Registerations',
      align: "right",
      render: (record) => {
        return (
          <div className='linkColor text-sm font-semibold cursor-pointer'
            onClick={() => getInfoAndRedirect(record)}
          > {record.registration_count} 
          </div>
        )
      }
    }
  ]

  // const getHorizontalBarchartdata = (arr) => {
  //   if (arr.length > 0) {
  //     let chartHeader = [['States', 'Registrations', { role: 'style' }, { role: 'annotation' }]];
  //     for (let index = 0; index < arr.length; index++) {
  //       if (arr[index].registration_count) {
  //         let all = [];
  //         all.push(arr[index].region_name, Number(arr[index].registration_count), arr[index].barColor, arr[index].state);
  //         console.log(all);
  //         chartHeader.push(all);
  //         var firstFive = chartHeader.slice(0,6);
  //         console.log(firstFive);
  //         console.log(chartHeader);
  //       }
  //     }
  //     setStateregisterationData(firstFive);
  //   }
  // }
 

  const getStartandEnddate = (value, dateString) => {
    setPeriod(null);
    setStartdate(dateString[0]);
    setEnddate(dateString[1]);
  }

  const getHorizontalBarchartdata = () => {
    fetchAdminApi(`/total-registrations-US?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
      (res) => {
        if (res?.data) {
          setStateregisterationData(res.data);
          setFilteredstates(res.data);
          let chartHeader = [['States', 'Registrations', { role: 'annotation' }]];
          let totalRegPoints = 0;
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].registration_count) {
              let all = [];
              all.push(res.data[index].region_name, Number(res.data[index].registration_count), res.data[index].registration_count);
              chartHeader.push(all);
              totalRegPoints += Number(res.data[index].registration_count)
              setRegistrationcount(totalRegPoints);
              // var firstTen = chartHeader.slice(0, 11);
              // console.log(firstTen);
              // let onlyStates = firstTen.slice(1);
              // console.log(onlyStates);
              // var onlyTen = [];
              // for (var i = 0; i < onlyStates.length; i++) {
              //   onlyTen.push(onlyStates[i][1]);
              // }
              // console.log(onlyTen);
              // let sum = 0;
              // for (let i = 0; i < onlyTen.length; i++) {
              //   sum += onlyTen[i];
              // }
              // console.log(sum);
              // setFirstfivestateregistrationcount(sum);

              if (chartHeader.length > 10) {
               
                var firstTen = chartHeader.slice(0, 11);
                chartHeader = firstTen;
              }
            }
          }
          setStateregisterationData(chartHeader);
          setRegistrationcount(totalRegPoints);
        } else {
          setRegistrationcount(0);
          setFilteredstates([]);
        }
      })
  }
  
  const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value)
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

  return (
    <div className='cursorChange'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className='p-4 min-h-screen bg-gray-100'>
            <div>
              <Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
                className='bg-neutral-100 shadow-md p-3 mb-3' />
            </div>
            <Card title="REGISTRATIONS BY US STATES" className="addQcard" hoverable>
              <Row>
                <Col xs={24} md={8} lg={8}>
                  <Table
                    bordered
                    dataSource={filteredstates}
                    columns={stateTabColumns}
                    className='registrationTable'
                    rowKey={(record) => record.region_name}
                    title={() => { return <div className="font-bold text-base text-white">{`REGISTRATIONS`}</div> }}
                    size="small"
                    summary={(pageData) => {
                      console.log(pageData);
                      // let totalPoints = 0;
                      // pageData.forEach(({ registration_count }) => {
                      //   totalPoints += Number(registration_count)
                      //   //setRegistrationcount(totalPoints);
                      // });
                      return (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,"is_total")}> {registrationcount}</div></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      )
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Card hoverable className=" mb-2 ml-3">
                    <Form form={form} onFinish={submitDates} className='countriesByregistrationFilters'>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item label="From">
                            <RangePicker
                              defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
                              format={dateFormat}
                              className='w-full'
                              onChange={getStartandEnddate}
                              allowClear={false}
                              value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Period">
                            <Select className='w-full rounded-md'
                              placeholder={constants.Period}
															onChange={onChangePeiod}
                              value={period}
                            >
                              {constants.periodOptions.map((option, index) => (
                                <Select.Option key={option} value={index}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                  <div>
                    <Card className='ml-5' hoverable>
                      {registrationcount != 0 ?
                        <Chart
                          chartType="BarChart"
                          width={'100%'}
                          height={'600px'}
                          loader={<div>Loading Chart</div>}
                          data={stateregisterationdata}
                          options={options}
                        />
                        :
                        <div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
                      }
                    </Card>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RegistrationsByUSstates

import React, { useState, useEffect } from 'react';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Empty
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import dayjs from 'dayjs';
import { Chart } from "react-google-charts";
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
const { RangePicker } = DatePicker;

const RegistrationsByTopTenUniversities = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [registrationdata, setRegistrationData] = useState([]);
	const [universityregisterationdata, setUniversityregisterationData] = useState([]);
	const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [period, setPeriod] = useState(0);
	const [countries,setCountries] =useState([]);
	const [registrationcount, setRegistrationcount] = useState();
	const newCrumb = [<Link key={1} to="/registration-by-university" className="!text-black font-semibold">Top 10 University Registrations</Link>]
	const dateFormat = 'MM/DD/YYYY';
	const [year, setYear] = useState(dayjs().year());

	useEffect(()=>{
		getAllCountries();
	},[])

	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}

	const getCountryCode = (id)=>{
		const selectedCountry = countries.filter((each) => (each.country_id == id));
		return selectedCountry[0].country_code;
	}

	useEffect(() => {
		getUniversityrelatedBarchartdata();
	}, [startdate, enddate])

	const getInfoAndRedirect = (record,rolevalue,roleTitle,is_total) => {
		if (startdate && enddate) {
			let universityRegistrationData = {};
			universityRegistrationData.startdate = startdate;
			universityRegistrationData.enddate = enddate;
			if(is_total === null){
				universityRegistrationData.country = record.country_id;
				universityRegistrationData.state = record.state_id;
				universityRegistrationData.stateCode = record.region_code;
				universityRegistrationData.countryCode = getCountryCode(record.country_id);
				universityRegistrationData.university = record.university_id;
				universityRegistrationData.universityName = record.university_name;
			}
			if(is_total !== null && record?.university_id){
				universityRegistrationData.university = record.university_id;
				universityRegistrationData.universityName = record.university_name;
			}
			if(roleTitle !== null || roleTitle !== null ){
				universityRegistrationData.roletitle = roleTitle;
				universityRegistrationData.occupation = rolevalue;
			}
			universityRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;
			navigate('/registered-students', { state: { universityRegData: universityRegistrationData } });
		}
	}

	const options = {
		title: 'Top 10 University registrations',
		titleTextStyle: {
			fontSize: 20,
			bold: 'true',
			color: '#8a1253',
		},
		vAxis: {
			title: 'Universities',
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "50%" },
		bars: "horizontal",
		colors: ["#5dacbd", "#fdb44b", '#a696c8', '#ff8585'],
		is3D: true,
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		'hAxis': {
			title: 'Registrations', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		chartArea: { width: "55%", height: "80%" },
		isStacked: true,
		tooltip: { isHtml: true },
	};

	const getUniversityrelatedBarchartdata = () => {
		fetchAdminApi(`/students-by-university?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
			(res) => {
				if (res?.data) {
					let totalCount = 0;
					setUniversityregisterationData(res.data);
					setRegistrationData(res?.data.slice(0, 10));

					let chartHeader = [['University', 'Students', 'Professionals', 'Faculty', 'Others']];
					for (let index = 0; index < res.data.length; index++) {
						totalCount += Number(res.data[index].total_count)
						setRegistrationcount(totalCount);
						if (res.data[index].total_count) {
							let all = [];
							all.push(res.data[index].university_name, Number(res.data[index].student_count
							), Number(res.data[index].professional_count), Number(res.data[index].faculty_count
							), Number(res.data[index].other_count));
							chartHeader.push(all);
							if (chartHeader.length > 10) {
								let firstTen = chartHeader.slice(0, 11);
								chartHeader = firstTen;
							}
						}
					}
					setUniversityregisterationData(chartHeader);
				} else {
					setRegistrationData([]);
					setRegistrationcount(0);
				}
			})
	}
	const getStartandEnddate = (value, dateString) => {
		setPeriod(null);
		setStartdate(dateString[0]);
		setEnddate(dateString[1]);
	}
	const universityTabColumns = [
		{
			title: 'University',
			key: 'University',
			dataIndex:'university_name',
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'region_code',
			align: 'center',
		},
		{
			title: 'Students',
			key: 'Students',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,0,'Student')}
					> {record.student_count}
					</div>
				)
			}
		},
		{
			title: 'Professionals',
			key: 'Professionals',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,1,'Professional')}
					> {record.professional_count}
					</div>
				)
			}
		},
		{
			title: 'Faculty',
			key: 'Faculty',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,2,'Faculty')}
					> {record.faculty_count}
					</div>
				)
			}
		},
		{
			title: 'Other',
			key: 'Other',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,3,'other')}
					> {record.other_count}
					</div>
				)
			}
		},
		{
			title: 'Total Registrations',
			key: 'Total Registrations',
			// dataIndex: 'total_count',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,null,null,"is_total")}
					> {record.total_count}
					</div>
				)
			}
		},
	];

	const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		let year =dayjs(sted?.startDate).year();
		setYear(year);
		setPeriod(value)
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title="TOP 10 REGISTRATIONS BY UNIVERSITIES" className="addQcard"
							extra={
								<Link to="/registration-by-all-universities" className="editCard-extra font-bold text-base linkColor">
									More Info...
								</Link>
							}
							hoverable>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card hoverable className="mb-3">
										<Form form={form} className='countriesByregistrationFilters'>
											<Row gutter={24}>
												<Col span={12}>
													<Form.Item label="From">
														<RangePicker
															className='w-11/12'
															defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
															format={dateFormat}
															onChange={getStartandEnddate}
															allowClear={false}
															value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label="Period">
														<Select className='w-11/12 rounded-md'
															value={period}
															placeholder={constants.Period}
															onChange={onChangePeiod}
														>
															{constants.periodOptions.map((option, index) => (
																<Select.Option key={option} value={index}>
																	{option}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Card>
									<Card className='' hoverable>
										{registrationcount != 0
											?
											<Chart
												chartType="BarChart"
												className='w-full'
												width={'100%'}
												height={'500px'}
												loader={<div>Loading Chart</div>}
												data={universityregisterationdata}
												options={options}
											/>
											:
											<div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
										}

									</Card>
								</Col>
							</Row>
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card className='mt-3' hoverable>
										<Table
											bordered
											dataSource={registrationdata}
											columns={universityTabColumns}
											className='registrationTable'
											pagination={false}
											rowKey={(record) => record.university_name}
											scroll={{ x: 768 }}
											title={() => { return <div className="font-bold text-base text-white">{`UNIVERSITY REGISTRATIONS FOR THE ${period == 0 || period == 1 ? `YEAR ${year}`: "SELECTED PERIOD"}`}</div> }}
											size="small"
											summary={(pageData) => {
												let totalPoints = 0;
												let totalstudentPoints = 0;
												let totalprofPoints = 0;
												let totalfacultyPoints = 0;
												let totalotherPoints = 0;
												pageData.forEach(({ total_count, student_count, professional_count
													, faculty_count
													, other_count
												}) => {
													totalPoints += Number(total_count);
													totalstudentPoints += Number(student_count);
													totalprofPoints += Number(professional_count);
													totalfacultyPoints += Number(faculty_count);
													totalotherPoints += Number(other_count);
												});
												return (
													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
															<Table.Summary.Cell index={1}></Table.Summary.Cell>
															<Table.Summary.Cell index={2} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,0,'Student',"is_total")}> {totalstudentPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={3} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,1,'Professional',"is_total")}> {totalprofPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={4} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,2,'Faculty',"is_total")}> {totalfacultyPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={5} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,3,'other',"is_total")}> {totalotherPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={6} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,null,null,"is_total")}> {totalPoints}</div></Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>
												)
											}}
										/>
									</Card>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default RegistrationsByTopTenUniversities

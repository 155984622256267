import React, { useState, useEffect } from 'react';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api';
const { RangePicker } = DatePicker;

const RegistrationsByUniversities = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [universityRegistrations, setUniversityRegistrations] = useState([]);
	const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [countries, setCountries] = useState([]);
	const newCrumb = [<Link key={1} to="/registration-by-university" className=" font-semibold">Top 10 University Registrations</Link>, <Link key={1} to="/registration-by-all-universities" className="!text-black font-semibold">All University Registrations</Link>]
	const [period, setPeriod] = useState(0);
	const [year, setYear] = useState(dayjs().year());

	const dateFormat = 'MM/DD/YYYY';

	useEffect(() => {
		getUniversityRegistrationData();
	}, [startdate, enddate])

	const getUniversityRegistrationData = () => {
		fetchAdminApi(`/students-by-university?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
			(res) => {
				setUniversityRegistrations(res?.data);
			})
	}

	useEffect(() => {
		getAllCountries();
	}, [])

	const getAllCountries = () => {
		fetchAdminApi(`/admin-countries`, 'get').then((res) => {
			if (res?.data && res?.data.length > 0) {
				setCountries(res?.data);
			} else {
				setCountries([]);
			}
		})
	}


	const options = {
		title: 'University registrations for the year 2023',
		titleTextStyle: {
			fontSize: 20,
			bold: 'true',
			color: '#8a1253',
		},
		vAxis: {
			title: 'Universities',
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "50%" },
		bars: "horizontal",
		// colors: ["#fdb44b", "#8ed6ff", '#cf7979','#41d8bf'],
		is3D: true,
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
		'hAxis': {
			title: 'Registrations', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},

		chartArea: { width: "55%", height: "80%" },
		isStacked: true,
		tooltip: { isHtml: true },
	};

	const getCountryCode = (id) => {
		const selectedCountry = countries.filter((each) => (each.country_id == id));
		return selectedCountry[0].country_code;
	}

	const getInfoAndRedirect = (record, rolevalue, roleTitle,is_total) => {
		if (startdate && enddate) {
			let alluniversityRegistrationData = {};
			alluniversityRegistrationData.startdate = startdate;
			alluniversityRegistrationData.enddate = enddate;
			if(is_total === null){
				alluniversityRegistrationData.country = record.country_id;
				alluniversityRegistrationData.state = record.state_id;
				alluniversityRegistrationData.stateCode = record.region_code;
				alluniversityRegistrationData.countryCode = getCountryCode(record.country_id);
				alluniversityRegistrationData.university = record.university_id;
				alluniversityRegistrationData.universityName = record.university_name;
			}
			if(is_total !== null && record?.university_id){
				alluniversityRegistrationData.university = record.university_id;
				alluniversityRegistrationData.universityName = record.university_name;
			}
			if(roleTitle !== null || roleTitle !== null ){
				alluniversityRegistrationData.occupation = rolevalue;
				alluniversityRegistrationData.roletitle = roleTitle;
			}
			alluniversityRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;
			navigate('/registered-students', { state: { allUniversityRegData: alluniversityRegistrationData } });
		}
	}

	const getStartandEnddate = (value, dateString) => {
		setPeriod(null);
		setStartdate(dateString[0]);
		setEnddate(dateString[1]);
	}
	const universityTabColumns = [
		{
			title: 'University',
			key: 'University',
			render: (record) => {
				return (
					<div className='text-sm'> {record.university_name}</div>
				)
			}
		},
		{
			title: 'State',
			key: 'State',
			dataIndex: 'region_code',
			align: 'center',
		},
		{
			title: 'Students',
			key: 'Students',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,0,'Student')}
					> {record.student_count}
					</div>
				)
			}
		},
		{
			title: 'Professionals',
			key: 'Professionals',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,1,'Professional')}
					> {record.professional_count}
					</div>
				)
			}
		},
		{
			title: 'Faculty',
			key: 'Faculty',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,2,'Faculty')}
					> {record.faculty_count}
					</div>
				)
			}
		},
		{
			title: 'Other',
			key: 'Other',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,3,'other')}
					> {record.other_count}
					</div>
				)
			}
		},
		{
			title: 'Total Registrations',
			key: 'Total Registrations',
			align: 'right',
			render: (record) => {
				return (
					<div className='linkColor text-sm font-semibold cursor-pointer'
						onClick={() => getInfoAndRedirect(record,null,null,"is_total")}
					> {record.total_count}
					</div>
				)
			}
		},
	];

	const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value);
		let year =dayjs(sted?.startDate).year();
		setYear(year);
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

	return (
		<div className="cursorChange">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card hoverable className="addQcard" title="REGISTRATIONS BY UNIVERSITIES">
							<Row>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card hoverable>
										<Form form={form} className='countriesByregistrationFilters'>
											<Row gutter={24}>
												<Col span={12}>
													<Form.Item label="From">
														<RangePicker
															placeholder={["From", "To"]}
															className='w-11/12'
															defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
															format={dateFormat}
															onChange={getStartandEnddate}
															allowClear={false}
															value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label="Period">
														<Select className='w-11/12 rounded-md'
															value={period}
															placeholder={constants.Period}
															onChange={onChangePeiod}
														>
															{constants.periodOptions.map((option, index) => (
																<Select.Option key={option} value={index}>
																	{option}
																</Select.Option>
															))}
														</Select>

													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Card>
									<Card className='mt-5' hoverable>
										<Table
											bordered
											dataSource={universityRegistrations}
											columns={universityTabColumns}
											className='registrationTable'
											rowKey={(record) => record.university_name}
											scroll={{ x: 768 }}
											title={() => { return <div className="font-bold text-base text-white">{`UNIVERSITY REGISTRATIONS FOR THE ${period == 0 || period == 1 ? `YEAR ${year}`: "SELECTED PERIOD"}`}</div> }}
											size="small"
											summary={(pageData) => {
												let totalstudentsPoints = 0;
												let totalprofPoints = 0;
												let totalfacultyPoints = 0;
												let totalotherPoints = 0;
												let totalPoints = 0;
												pageData.forEach(({ student_count, professional_count, faculty_count, other_count, total_count }) => {
													totalstudentsPoints += Number(student_count)
													totalprofPoints += Number(professional_count)
													totalfacultyPoints += Number(faculty_count)
													totalotherPoints += Number(other_count)
													totalPoints += Number(total_count)
												});
												return (
													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
															<Table.Summary.Cell index={1} className="font-bold text-base text-center"></Table.Summary.Cell>
															<Table.Summary.Cell index={2} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,0,'Student',"is_total")}> {totalstudentsPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={3} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,1,'Professional',"is_total")}> {totalprofPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={4} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,2,'Faculty',"is_total")}> {totalfacultyPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={5} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,3,'other',"is_total")}> {totalotherPoints}</div></Table.Summary.Cell>
															<Table.Summary.Cell index={6} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getInfoAndRedirect(null,null,null,"is_total")}> {totalPoints}</div></Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>
												)
											}}
										/>
									</Card>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default RegistrationsByUniversities

import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Table,
  Form,
  DatePicker,
  Select,
  Empty
} from 'antd';
import { getCustomisedBreadcrumbs, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import { Chart } from "react-google-charts";
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchAdminApi } from '../../../services/api';

const RegistrationsByRoles = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [roleregisterationdata, setRoleregisterationData] = useState([]);
  const [registrationdata, setRegistrationData] = useState([]);
  const [startdate, setStartdate] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
  const [enddate, setEnddate] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
  const [period, setPeriod] = useState(0);
  const [registrationcount, setRegistrationcount] = useState();
  const { RangePicker } = DatePicker;
  const newCrumb = [<Link key={1} to="/registration-by-roles" className="!text-black font-semibold">Registrations by Roles</Link>]
  const dateFormat = 'MM/DD/YYYY';

  const getInfoAndRedirect = (record,is_total=null) => {
    if (startdate && enddate) {
      let roleRegistrationData = {};
      roleRegistrationData.startdate = startdate;
      roleRegistrationData.enddate = enddate;
      if (is_total === null) {
        roleRegistrationData.occupation = record.occupation;
        roleRegistrationData.roleName = record.role;
      }
      roleRegistrationData.criteria = `Registrations from ${startdate} to ${enddate}`;      
      navigate('/registered-students', { state: { roleRegData: roleRegistrationData } });
    }
  }

  const roleTabColumns = [
    {
      title: 'Roles',
      key: 'Roles',
      align: "left",
      render: (record) => {
        return (
          <div className='linkColor text-sm font-semibold cursor-pointer'
            onClick={() => getInfoAndRedirect(record)}
          > {record.role} ({record.role.charAt(0)})
          </div>
        )
      }
    },
    {
      title: 'Registrations',
      key: 'Registerations',
      align: "right",
      render: (record) => {
        return (
          <div className='linkColor text-sm font-semibold cursor-pointer'
            onClick={() => getInfoAndRedirect(record)}
          > {record.total}
          </div>
        )
      }
    }
  ]
  const getStartandEnddate = (value, dateString) => {
    setPeriod(null);
    setStartdate(dateString[0]);
    setEnddate(dateString[1]);
  }
  const roleRegistrationData = [
    {
      role: 0,
      role_name: "Student",
      count: "2048",
      barColor: '#9896f1'
    },
    {
      role: 1,
      role_name: "Professional",
      count: "1000",
      barColor: '#a2c11c'
    },
    {
      role: 2,
      role_name: "Faculty",
      count: "500",
      barColor: '#fdc57b'
    },
    {
      role: 3,
      role_name: "Other",
      count: "200",
      barColor: '#e84a5f'
    }
  ]

  useEffect(() => {
    getRolerelatedColumnchartdata();
  }, [startdate, enddate]);

  const getRolerelatedColumnchartdata = () => {
    fetchAdminApi(`/students-by-role?start_date=${changeDateFormat(startdate)}&end_date=${changeDateFormat(enddate)}`, "get").then(
      (res) => {
        if (res?.data) {
          setRoleregisterationData(res?.data);
          setRegistrationData(res?.data);
          let chartHeader = [['Roles', 'Registrations']];
          let totalRoleRegs = 0;
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].total) {
              let all = [];
              all.push(res.data[index].role, Number(res.data[index].total));
              chartHeader.push(all);
              totalRoleRegs += Number(res.data[index].total)
              setRegistrationcount(totalRoleRegs);
            }
          }
          setRoleregisterationData(chartHeader);
        } else {
          setRegistrationData([]);
          setRegistrationcount(0);
        }
      })
  }

  const submitDates = (values) => {
    console.log(values);
  }

  const options = {
    title: `Role Registrations for the period ${startdate} - ${enddate}`,
    titleTextStyle: {
      fontSize: 20,
      bold: 'true',
      color: '#8a1253',
    },
    vAxis: {
      title: 'Registrations',
      textStyle: {
        fontSize: 14,
        color: '#000',
        bold: 'true'
      },
      titleTextStyle: {
        fontSize: 16,
        color: '#8a1253',
        bold: 'true',
      },
    },
    bar: { groupWidth: "30%" },
    is3D: true,
    animation: {
      duration: 1000,
      easing: 'linear',
      startup: true
    },
    legend: { position: 'top' },
    'hAxis': {
      title: 'Roles', gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
        fontSize: 16,
        color: '#8a1253',
        bold: 'true',
      },
    },
    colors: ['#9b5d73'],
   // chartArea: { left: 140, top: 70, width: "90%", height: "60%" },
    tooltip: { isHtml: true },
  };

  const onChangePeiod = (value) => {
		let sted = getStartDateAndEndDate(value);
		setPeriod(value)
		setStartdate(dayjs(sted?.startDate).format(constants.DateFormat));
		setEnddate(dayjs(sted?.endDate).format(constants.DateFormat));
	};

  return (
    <div className='cursorChange'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className='p-4 min-h-screen bg-gray-100'>
            <div>
              <Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
                className='bg-neutral-100 shadow-md p-3 mb-3' />
            </div>
            <Card title="REGISTRATIONS BY ROLES" className="addQcard" hoverable>
              <Row>
                <Col xs={24} md={8} lg={8}>
                  <Table
                    size="middle"
                    bordered
                    dataSource={registrationdata}
                    columns={roleTabColumns}
                    pagination={false}
                    className='registrationTable'
                    rowKey={(record) => record.role_name}
                    title={() => { return <div className="font-bold text-base text-white">{`REGISTRATIONS FOR THE YEAR 2023`}</div> }}
                    summary={(pageData) => {
                      let totalRegs = 0;
                      pageData.forEach(({ total }) => {
                        totalRegs += Number(total)
                      });
                      return (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="font-bold text-base text-left">Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1} className="font-bold text-base text-right"><div className='linkColor text-sm font-semibold cursor-pointer' onClick={() => getInfoAndRedirect(null,"is_total")}>{totalRegs}</div></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      )
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Card hoverable className=" mb-2 ml-3">
                    <Form form={form} onFinish={submitDates} className='countriesByregistrationFilters'>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item label="From">
                            {/* <DatePicker className='w-full border-cyan-600 border-2'></DatePicker> */}
                            <RangePicker className='w-full'
                              defaultValue={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
                              format={dateFormat}
                              onChange={getStartandEnddate}
                              allowClear={false}
                              value={[dayjs(startdate, dateFormat), dayjs(enddate, dateFormat)]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Period">
                            <Select className='w-full rounded-md'
                              value={period}
                              placeholder={constants.Period}
                              onChange={onChangePeiod}
                            >
                              {constants.periodOptions.map((option, index) => (
                                <Select.Option key={option} value={index}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                  <div>
                    <Card className='ml-5' hoverable>
                      {registrationcount != 0
                        ?
                        <Chart
                          chartType="ColumnChart"
                          width={'100%'}
                          height={'400px'}
                          loader={<div>Loading Chart</div>}
                          data={roleregisterationdata}
                          options={options}
                        />
                        :
                        <div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
                      }

                    </Card>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RegistrationsByRoles

import React,{useState,useEffect} from 'react';
import { getCustomisedBreadcrumbs, setStartandEnddate, changeDateFormat, getStartDateAndEndDate } from '../../../functions/functions';
import constants from '../../../constants/constants';
import {
	Breadcrumb,
	Row,
	Col,
	Card,
	Table,
	Form,
	DatePicker,
	Select,
	Empty,
	Button,
	Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchAdminApi } from '../../../services/api/index';
import { Chart } from "react-google-charts";
const { RangePicker } = DatePicker;

const StudyGuideDownloads = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { studyGuideObject } = location.state || {};

	let queryString = window.location.href;
	const isStudyGuideDownload = queryString.substring(queryString.lastIndexOf('/') + 1) === 'study-guide-downloads' ? true : false;
	
	const [form] = Form.useForm();
	const dateFormat = 'MM/DD/YYYY';
	const [blocktype, setBlockType] = useState(sessionStorage.getItem('block'));
	const [newCrumb, setNewCrumb] = useState(blocktype == 0 ? <Link key={0} to="/total-study-guide-downloads" className="!text-black font-semibold">
		{constants.TotalStudyGuideDownload}</Link> : <Link key={0} to="/study-guide-downloads" className="!text-black font-semibold">
		{constants.StudyGuideDownload}</Link>);
	//Total Study Guide Download related states(block 1)
	const [totalstudyguidedownloads, setTotalStudyGuideDownloads] = useState([]);
	const [totalguideschartdata, setTotalGuidesChartData] = useState([]);
	const [startdatefortotaldownloads, setStartDateforTotalDownloads] = useState(dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefortotaldownloads, setEndDateforTotalDownloads] = useState(dayjs().endOf('year').format('MM/DD/YYYY'));
	const [periodfortotaldownloads, setPeriodForTotalDownloads] = useState(0);

	//Study Guide  chapterwise Downloads related states(block 2)
	const [studyguidedownloads,setStudyGuideDownloads] = useState([]);
	const [studyguideChartdata, setStudyGuideChartData] = useState([]);
	const [startdatefordownloads, setStartDateforDownloads] = useState(studyGuideObject ? studyGuideObject?.startdate : dayjs().startOf('year').format('MM/DD/YYYY'));
	const [enddatefordownloads, setEndDateforDownloads] = useState(studyGuideObject ? studyGuideObject?.enddate : dayjs().endOf('year').format('MM/DD/YYYY'));
	const [periodfordownloads, setPeriodForDownloads] = useState(0);

	const [totalguidecount,setTotalGuideCount] = useState();

	useEffect(()=>{
		if (blocktype == 0){
			setNewCrumb(<Link key={0} to="/total-study-guide-downloads" className="!text-black font-semibold">
				{constants.TotalStudyGuideDownload}</Link>)
		}
	}, [blocktype])

	useEffect(()=>{
		//getStudyGuideRelatedData();
		if (blocktype && (startdatefortotaldownloads && enddatefortotaldownloads) || (startdatefordownloads && enddatefordownloads)) {
			getStudyGuideRelatedData();
		}
	}, [blocktype,startdatefortotaldownloads, enddatefortotaldownloads, startdatefordownloads, enddatefordownloads])

	useEffect(()=>{
		if(location.state){
			if (studyGuideObject) {
				setBlockType(1);
				setStartDateforDownloads(studyGuideObject?.startdate);
				setEndDateforDownloads(studyGuideObject?.enddate);
				setNewCrumb([<Link key={0} to="/total-study-guide-downloads" onClick={() => setBlockType(0)} className="font-semibold">
					{constants.TotalStudyGuideDownload}</Link>, <a key={1} className="!text-black font-semibold">{constants.StudyGuideDownload}</a>])
			}
		}
	}, [location.state])

	const getStartAndEnddateForTotalDownloads = (value, dateString) => {
		setStartDateforTotalDownloads(dateString[0]);
		setEndDateforTotalDownloads(dateString[1]);
	}

	const getStartAndEnddateForChapterwiseDownloads = (value, dateString) => {	
		setStartDateforDownloads(dateString[0]);
		setEndDateforDownloads(dateString[1]);
	}

	const getStartAndEndDate = (month, year) => {
		// Create a dayjs object for the given month and year
		const date = dayjs(`${year}-${month}-01`);
	
		// Get the start date of the month
		const startDate = date.startOf('month').format('MM/DD/YYYY');
	
		// Get the end date of the month
		const endDate = date.endOf('month').format('MM/DD/YYYY');
	
		return { startDate, endDate };
	};

	const getDataAndRedirect = (record,is_total=null) => {
		const { startDate, endDate } = getStartAndEndDate(record?.month, record?.year);
		let recordData = {};
		let sd,ed;
		if(is_total !== null){
			sd=startdatefortotaldownloads;
			ed=enddatefortotaldownloads;
		}else{
			sd=startDate;
			ed=endDate;
		}
		recordData.startdate = sd;
		recordData.enddate = ed;
		navigate('/study-guide-downloads', { state: { studyGuideObject: recordData }});
	}

	const totalStudyGuideDownloadTableColumns = [
		{
			title: 'Month/Year',
			key: 'Month',
			align: 'left',
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getDataAndRedirect(record, index)}>
						{record.month_in_words}	{record.year}
					</div>
				)
			}
		},
		{
			title: 'Downloads',
			key: 'Tests',
			align: "right",
			render: (text, record, index) => {
				return (
					<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getDataAndRedirect(record, index)}>
						{record.total}
					</div>
				)
			}
		}
	]

	const StudyGuideDownloadTableColumns = [
		{
			title: 'Ch#',
			key: 'chapter_id',
			align: 'center',
			dataIndex:'chapter_id'
		},
		{
			title: 'Title',
			key: 'chapter_title',
			align: 'left',
			dataIndex: 'chapter_title',
		},
		{
			title: 'Downloads',
			key: 'total',
			align: 'right',
			dataIndex: 'total',
				render: (text, record, index) => {
					return (
						<div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => redirectToStudentResult(record)}>
							{record.total}
						</div>
					)
				}
		}
	];

	const redirectToStudentResult = (recordData=null) => {
		if(recordData===null){
			recordData = {}
			recordData.isTotal = true; 
		}else{
			recordData.isTotal = false;
		}
		let startD = dayjs(startdatefordownloads).format(constants.DateFormat);
		let endD = dayjs(enddatefordownloads).format(constants.DateFormat);
		recordData.sd = startD;
		recordData.ed = endD;
		navigate('/study-guide-result', { state: { studyGuidewiseObject: recordData } });
	};

	const getStudyGuideRelatedData = () => {
		fetchAdminApi(blocktype == 0 ? `/get-total-studyguide-downloads?start_date=${dayjs(startdatefortotaldownloads).format("YYYY-MM-DD")}&end_date=${dayjs(enddatefortotaldownloads).format("YYYY-MM-DD")}`: `/studyguide-downloads-chapterwise?start_date=${dayjs(startdatefordownloads).format("YYYY-MM-DD")}&end_date=${dayjs(enddatefordownloads).format("YYYY-MM-DD")}`, "get").then(
			(res) => {
				if (res && res?.data.all.length > 0) {
					let chartHeaderfortotaldownloads = [['Month','Study guides', { role: 'annotation' }]];
					let chartHeaderforchapterwisedownloads = [['Chapters', 'Study guides', { role: 'annotation' }]];
					for (let index = 0; index < res?.data?.all.length; index++) {
						let totaldownloads = [];
						let chapterwisedownloads = [];
						blocktype == 0 ?
						totaldownloads.push(`${(res?.data.all[index].month_in_3_words).toUpperCase()} ${res?.data.all[index].year}`, Number(res?.data.all[index].total), Number(res?.data.all[index].total))
						:
						chapterwisedownloads.push(`Ch #${(res?.data.all[index].chapter_id)}`, Number(res?.data.all[index].total), Number(res?.data.all[index].total));
						blocktype == 0 ? chartHeaderfortotaldownloads.push(totaldownloads) : chartHeaderforchapterwisedownloads.push(chapterwisedownloads);
					}
					blocktype == 0 ? setTotalStudyGuideDownloads(res?.data?.all) : setStudyGuideDownloads(res?.data?.all);
					blocktype == 0 ? setTotalGuidesChartData(chartHeaderfortotaldownloads) : setStudyGuideChartData(chartHeaderforchapterwisedownloads);
					setTotalGuideCount(res?.data.all_total);
				} else {
				setTotalStudyGuideDownloads([])
				setTotalGuidesChartData([])
				setStudyGuideDownloads([]);
				setTotalGuideCount(0);
				}
			})
	}

	const options = {
		title: `Study guides downloaded for the period ${blocktype == 0 ? startdatefortotaldownloads : startdatefordownloads} - ${blocktype == 0 ? enddatefortotaldownloads : enddatefordownloads}`,
		titleTextStyle: {
			fontSize: 19,
			bold: 'true',
			color: '#8a1253',
		},
		vAxis: {
			title: blocktype == 0 ? 'Month / year':'Chapters',
			textStyle: {
				fontSize: 14,
				color: '#000',
				bold: 'true'
			},
			titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
		},
		bar: { groupWidth: "60%" },
		bars: "horizontal",
		is3D: true,
		colors: ['#f57b51'],
		animation: {
			duration: 1000,
			easing: 'linear',
			startup: true
		},
    legend: { position: 'top' },
		'hAxis': {
			title: 'No. of Study guides',
			gridlines: { count: -10 }, minorGridlines: 'NULL', textStyle: { fontSize: 14, color: 'black', bold: 'true' }, format: '####', titleTextStyle: {
				fontSize: 16,
				color: '#8a1253',
				bold: 'true',
			},
			//ticks: [0,10,20,30,40,50]
		},

		//chartArea: { left: 140, top: 70, width: "90%", height: "80%" },
		tooltip: { isHtml: true },
	};

	const onChangePeiod = (value,type) => {
		let sted = getStartDateAndEndDate(value);
		if (type === "1") {
			setPeriodForTotalDownloads(value);
			setStartDateforTotalDownloads(dayjs(sted?.startDate).format(constants.DateFormat));
			setEndDateforTotalDownloads(dayjs(sted?.endDate).format(constants.DateFormat));
		} else {
			setPeriodForDownloads(value);
			setStartDateforDownloads(dayjs(sted?.startDate).format(constants.DateFormat));
			setEndDateforDownloads(dayjs(sted?.endDate).format(constants.DateFormat));
		}
	};

	return (
		<div className='cursorChange'>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className='p-4 min-h-screen bg-gray-100'>
						<div>
							<Breadcrumb items={getCustomisedBreadcrumbs(constants.analysisPageCrumbs, newCrumb)}
								className='bg-neutral-100 shadow-md p-3 mb-3' />
						</div>
						<Card title='STUDY GUIDE DATA' className="addQcard" hoverable>
							<Row>
								<Col span={8}>
									<Table
										bordered
										dataSource={blocktype == 0 ? totalstudyguidedownloads : studyguidedownloads}
										columns={blocktype == 0 ? totalStudyGuideDownloadTableColumns : StudyGuideDownloadTableColumns}
										className='registrationTable'
										rowKey={(record) => record.month_in_words}
										title={() => { return <div className="font-bold text-base text-white">STUDY GUIDE DOWNLOADS</div> }}
										size="small"
										summary={(pageData) => {
											let totalDownloads = 0;
											pageData.forEach(({ total }) => {
												totalDownloads += Number(total);
											})
											return (
												isStudyGuideDownload ?
													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-left text-rose-800">Total</Table.Summary.Cell>
															<Table.Summary.Cell index={1}></Table.Summary.Cell>
															<Table.Summary.Cell index={2} className="font-bold text-base text-right"><span className='linkColor text-sm cursor-pointer' onClick={() => redirectToStudentResult()}>{totalDownloads}</span></Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>

													:

													<Table.Summary fixed>
														<Table.Summary.Row>
															<Table.Summary.Cell index={0} className="font-bold text-base text-left text-rose-800">Total</Table.Summary.Cell>
															<Table.Summary.Cell index={1} className="font-bold text-base text-right"><div className='linkColor font-semibold text-sm cursor-pointer' onClick={() => getDataAndRedirect(null,"is_total")}>{totalDownloads}</div></Table.Summary.Cell>
														</Table.Summary.Row>
													</Table.Summary>
											)
										}}
										pagination={false}
									/>
								</Col>
								<Col span={16}>
									<Card hoverable className=" mb-2 ml-3">
										<Form form={form} className='countriesByregistrationFilters'>
											<Row gutter={24}>
												<Col span={12}>
													<Form.Item label="From">
														{blocktype == 0 
														?
															<RangePicker
															defaultValue={[dayjs(startdatefortotaldownloads, dateFormat), dayjs(enddatefortotaldownloads, dateFormat)]}
															format={dateFormat}
															className='w-full'
															onChange={getStartAndEnddateForTotalDownloads}
															value={[dayjs(startdatefortotaldownloads, dateFormat), dayjs(enddatefortotaldownloads, dateFormat)]}
															allowClear={false}
															/>
															:
															<RangePicker
															defaultValue={[dayjs(startdatefordownloads, dateFormat), dayjs(enddatefordownloads, dateFormat)]}
															format={dateFormat}
															className='w-full'
															onChange={getStartAndEnddateForChapterwiseDownloads}
															value={[dayjs(startdatefordownloads, dateFormat), dayjs(enddatefordownloads, dateFormat)]}
															allowClear={false}
															/>
														}
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label="Period">
													{
															blocktype == 0
																?
																<Select className='w-full rounded-md'
																	placeholder={constants.Period}
																	onChange={(e)=>onChangePeiod(e,"1")}
																	value={periodfortotaldownloads}
																>
																	{constants.periodOptions.map((option, index) => (
																		<Select.Option key={option} value={index}>
																			{option}
																		</Select.Option>
																	))}
																</Select>
																:
																<Select className='w-full rounded-md'
																	onChange={(e)=>onChangePeiod(e,"2")}
																	placeholder={constants.Period}
																	value={periodfordownloads}
																>
																	{constants.periodOptions.map((option, index) => (
																		<Select.Option key={option} value={index}>
																			{option}
																		</Select.Option>
																	))}
																</Select>
													}
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Card>
									<div>
										<Card className='ml-5' hoverable>
											{
												(blocktype == 0 && totalguidecount != 0) || (blocktype == 1 && totalguidecount != 0)
												?
													<Chart
														chartType="BarChart"
														className='w-full'
														width={'100%'}
														height={'600px'}
														loader={<div>Loading Chart</div>}
														data={blocktype == 0 ? totalguideschartdata : studyguideChartdata}
														options={options}
													/>
													:
													<div className=''><Empty description={false}><span className="text-lg text-center font-bold font-sans text-sky-600">NO DATA TO DISPLAY</span></Empty></div>
											}
										
										</Card>
									</div>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default StudyGuideDownloads
